/* eslint-disable @next/next/next-script-for-ga */
import Head from "next/head";
import formatKeyword from "@/features/jobSearch/hooks/formatKeyword";

function SeoHead({
  title,
  description,
  canonical,
  breadcrumb,
  path,
  metatitle,
  pageData,
}) {
  return (
    <>
      <Head>
        <title>{title}</title>
        {pageData?.totalPages > 1 ? (
          pageData?.currentPage === 1 ? (
            <link rel="canonical" href={canonical} />
          ) : (
            <link
              rel="canonical"
              href={`${canonical}?pageNo=${pageData?.currentPage}&pageItems=20`}
            />
          )
        ) : (
          <link rel="canonical" href={canonical} />
        )}
        {pageData?.totalPages > 1 && (
          <>
            {pageData?.currentPage === 1 ? (
              ""
            ) : (
              <link
                rel="prev"
                href={
                  pageData?.currentPage === 2
                    ? canonical
                    : `${canonical}?pageNo=${
                        pageData?.currentPage - 1
                      }&pageItems=20`
                }
              />
            )}
            {pageData?.currentPage === pageData?.totalPages ? (
              ""
            ) : (
              <link
                rel="next"
                href={`${canonical}?pageNo=${
                  pageData?.currentPage + 1
                }&pageItems=20`}
              />
            )}
          </>
        )}
        <meta name="description" content={description} />
        <meta name="keywords" content={"jobs"} />
        <meta
          property="og:url"
          content={
            pageData?.totalPages > 1
              ? pageData?.currentPage === 1
                ? canonical
                : `${canonical}?pageNo=${pageData?.currentPage}&pageItems=20`
              : canonical
          }
        />
        <meta property="fb:app_id" content="826953751692351" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://email.jobsineducation.net/FacebookPost.jpg"
        />
        <meta property="og:site_name" content="Jobs in education" />
        <meta name="twitter:creator" content="@JobinEdu" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          property="twitter:image:src"
          content={"https://email.jobsineducation.net/TwitterPost.jpg"}
        />
        <meta name="twitter:site" content="jobsineducation" />
        {breadcrumb?.location ? (
          <script
            id="jobs-in-education-breadcrumb-schema"
            type="application/ld+json"
            async={true}
            dangerouslySetInnerHTML={{
              __html: `{
                "@context":"https://schema.org",
                "@type":"BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://www.jobsineducation.net"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "${breadcrumb.locationTitle}",
                  "item": "https://www.jobsineducation.net/${formatKeyword(
                    breadcrumb.locationTitle
                      .toLowerCase()
                      .replace(/[^\w\s]|_/g, " ")
                  )}"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "${breadcrumb.jobTitle} jobs",
                  "item": "https://www.jobsineducation.net/${formatKeyword(
                    breadcrumb.jobTitle
                      .toLowerCase()
                      .replace(/[^\w\s]|_/g, " ") + "-jobs"
                  )}"
                },{
                  "@type": "ListItem",
                  "position": 4,
                  "name": "${path?.replaceAll("-", " ")}"
                }]
              }`,
            }}
          />
        ) : (
          <script
            async={true}
            id="jobs-in-education-breadcrumb-schema"
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `{
                "@context":"https://schema.org",
                "@type":"BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://www.jobsineducation.net"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "${path?.replaceAll("-", " ")}"
                }]
              }`,
            }}
          />
        )}
      </Head>
    </>
  );
}

export default SeoHead;
